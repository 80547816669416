// src/components/Services.js
import React from "react";
// import '../assets/styles/Services.css';

import Accordion from "react-bootstrap/Accordion";
const Services = () => {
  return (
    <div className="container my-5">
      <div className="services-container row">
        <div className="left-section col-lg-3 col-md-12">
          <div className="row">
            <div className="intro-text col-lg-12 col-md-6">
              <p>What We Do</p>
            </div>
            {/* <div class=".d-md-none .d-lg-block border-bottom border-info"></div> */}
            <div className="audio-heading col-lg-12 col-md-6">
              <h2>A.U.D.I.O</h2>
            </div>
          </div>
        </div>
        <div className="right-section col-lg-9 col-md-12">
          <Accordion defaultActiveKey="0" className="">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <h3>AI & Analytics</h3>
              </Accordion.Header>
              <Accordion.Body>
                AI solutions, Data Analytics, Business Intelligence
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                <h3>User-Centered Design</h3>
              </Accordion.Header>
              <Accordion.Body>
                UI-UX Development, UX/UI Design, Custom Application
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>
                <h3>Digital Innovations</h3>
              </Accordion.Header>
              <Accordion.Body>
                Digital Marketing, Ecommerce Solutions
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>
                <h3>Integrated Software Solutions</h3>
              </Accordion.Header>
              <Accordion.Body>
                Software Development, Web & Mobile Apps
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>
                <h3>Optimized Cloud Services</h3>
              </Accordion.Header>
              <Accordion.Body>
                Cloud Deployment, Infrastructure Management
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
    </div>
  );
};

export default Services;
