// src/App.js
import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import Services from "./pages/Services";
import About from "./pages/About";
import "./assets/styles/global.css"; // Create a CSS file for styling
import ContactUs from "./pages/Contact";
import { Button } from "react-bootstrap";
import AnnouncementBanner from "./components/AnnouncementBanner";

function App() {
  const [showTopButton, setShowTopButton] = useState(false);
  useEffect(() => {
    const handleScrollButtonVisiblity = () => {
      window.scrollY > 200 ? setShowTopButton(true) : setShowTopButton(false);
    };
    window.addEventListener("scroll", handleScrollButtonVisiblity);
    return () => {
      window.removeEventListener("scroll", handleScrollButtonVisiblity);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <Router>
      <div className="">
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/services" element={<Services />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact-us" element={<ContactUs />} />
        </Routes>
        {showTopButton && (
          <div className="toptobutton">
            <button className="btn btn-dark " onClick={scrollToTop}>
              <i class="bi bi-arrow-up"></i> Scroll To Top
            </button>
          </div>
        )}

        <Footer />
      </div>
    </Router>
  );
}

export default App;
