import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeadphones } from "@fortawesome/free-solid-svg-icons"; // Example for Font Awesome
import agent from "../assets/images/agent.png";
const Contact = () => {
  return (
    <div className="contact-section container p-0">
      <div className="row align-items-center">
        <div className="col-lg-2 col-md-3">
          {/* Animated avatar (replace with your actual animated avatar component) */}
          <div className="avatar">
            <img src={agent} alt="Customer Service Avatar" />
          </div>
        </div>

        <div className="center-section-info col-lg-7 col-md-9">
          {/* Text block */}
          <div className="text-block">
            <h2>Got a project and Ready to discuss?</h2>
            <p>Hire us today and let’s craft something great together.</p>
          </div>
        </div>

        <div className="right-section-info col-lg-3 col-md-12">
          {/* Contact button */}
          <div className="cta-button align-middle">
            <a href="/contact-us" className="contact-button-home">
              {" "}
              <FontAwesomeIcon icon={faHeadphones} className="icon" />
              TALK TO US
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
