import React from "react";
import "../assets/styles/banner.css";
import { useLocation } from "react-router-dom";
import { Image } from "react-bootstrap";

const Banner = (props) => {
  const location = useLocation();
  const fileNameExt =
    (location.pathname === "/" ? "/home" : location.pathname) + ".png";
  const imgDir = "./img";
  const imgSrc = imgDir + fileNameExt;
  return (
    <div className="banner ">
      <div className="background-animation">
        <div className="star-ani"></div>
        <div className="cloud-ani"></div>
        <div className="triangle-ani"></div>
        <div className="circle-ani"></div>
        <div className="box-ani"></div>
      </div>
      <div className="container header-margin">
        <div className="row align-items-center justify-content-between">
          <div className="col-lg-8 col-md-12">{props.children}</div>
          <div className="col-lg-4 col-md-12">
            {imgSrc ? <Image src={imgSrc} className="w-100 h-75" /> : ""}
            {/* <img src={require("" + imgSrc)} /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
