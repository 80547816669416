// src/components/AnnouncementBanner.js

import React from "react";

const AnnouncementBanner = () => {
  return (
    <div className="announcement-banner">
      <p>
        Exciting News! We are officially recognized by the Department for
        Promotion of Industry and Internal Trade (DPIIT) under Startup India.
      </p>
    </div>
  );
};

export default AnnouncementBanner;
