// src/pages/Home.js
import React from "react";
import Hero from "../components/Hero";
import Services from "../components/Services";
import Contact from "../components/Contact";
import Banner from "../components/Banner";

const Home = () => {
  return (
    <main className="header-margin">
      {/* <Banner></Banner> */}
      <Hero />
      <Services />
      <Contact />
    </main>
  );
};

export default Home;
