// src/components/Hero.js
import React from "react";
// import '../assets/styles/Hero.css';
import hero from "../assets/images/hero.jpg";
import { Button } from "react-bootstrap";

const Hero = () => {
  return (
    <section className="container hero">
      <div className="hero-content row">
        <div className="hero-image col-lg-6 col-md-12">
          <img src={hero} alt="Hero" />
        </div>
        <div className="hero-text col-lg-6 col-md-12">
          <h1>Welcome to MARUMiTRA</h1>
          <samp>
            Advanced Software Solutions with Cutting-Edge Technologies
          </samp>
          <p>
            We are dedicated to providing advanced software solutions with
            cutting-edge technologies and AI-driven insights. Our mission is to
            innovate and excel in delivering high-quality software products and
            consultancy services to our clients worldwide.
          </p>
          <div className="hero-buttons">
            <Button variant="secondary" className="m-2" href="/about">
              Learn More
            </Button>
            <Button variant="secondary" href="/contact-us">
              Get Started
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
