import React from "react";
import Banner from "../components/Banner";
import { Button, Image } from "react-bootstrap";
import Contact from "../components/Contact";
import "../assets/styles/About.css";
import akshay from "../assets/images/akshay.png";
import piyush from "../assets/images/piyush.png";
import lokesh from "../assets/images/lokesh.png";
import dpiitCertificate from "../assets/images/certificate.png";
import dpiitLogo from "../assets/images/dpiit.webp";
import startupIndiaLogo from "../assets/images/startupindia-logo.webp";
const About = () => {
  return (
    <div className="">
      <Banner>
        <div class="welcome-content">
          <p class="animated animatedFadeInUp fadeInUp first">
            We approach Each customer
          </p>
          <h5 class="animated animatedFadeInUp fadeInUp second">
            {" "}
            With a Vision of a{" "}
          </h5>
          <h1 class="animated animatedFadeInUp fadeInUp text-uppercase third">
            {" "}
            long-term relationship
          </h1>
          <h4 class="animated animatedFadeInUp fadeInUp fourth">
            Through our quality work
          </h4>
          <p class="mb-4 mt-4 animated animatedFadeInUp fadeInUp fifth">
            Be Smart| Be Innovative| Be Delight with Orion infosolutions
          </p>
        </div>
      </Banner>
      <section className="about-company container ">
        <div className="row text-center mt-5">
          <h2>Why Choose MARUMiTRA?</h2>
          <p>
            At MARUMiTRA, we believe in harnessing the power of technology to
            transform businesses. Our dedicated team of experts specializes in
            delivering cutting-edge software solutions that drive innovation and
            success. We are committed to providing top-notch services in
            AI-powered solutions, UI-UX development, cloud deployment, and more.
          </p>
          <p>
            Our mission is to empower businesses by offering innovative and
            customized software solutions. We focus on understanding your unique
            needs and delivering solutions that enhance efficiency,
            productivity, and profitability. With a blend of advanced technology
            and professional ethics, MARUMiTRA ensures that your business stays
            ahead in the competitive landscape.
          </p>
          <p>
            What sets MARUMiTRA apart is our commitment to excellence and our
            client-centric approach. We work closely with our clients to
            understand their challenges and provide tailored solutions that
            drive growth and success. Our team of skilled professionals is
            dedicated to delivering high-quality services that meet the highest
            standards of performance and reliability.
          </p>
        </div>
      </section>

      <section id="expertTeam" className="executive-team mb-5">
        <h2>Executive Team</h2>
        <div class="container d-flex align-items-center justify-content-center flex-wrap">
          <div class="box">
            <div class="body">
              <div class="imgContainer bg-company ">
                <Image src={lokesh} className="h-80 w-80" />
              </div>
              <div class="content d-flex flex-column align-items-center justify-content-center">
                <div>
                  <h3 class="text-white fs-5">Lokesh Soni</h3>
                  <p class="fs-6 text-white">CEO & Co-Founder</p>
                  <p className="text-company">
                    His great business skills and intellect holds decentralized
                    navigation with genes of excellence, responsibility like
                    project execution and management, research and development,
                    sharpening the overall strategic core decisions of the
                    company.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="box">
            <div class="body">
              <div class="imgContainer">
                <Image src={akshay} alt="" className="bg-company" />
              </div>
              <div class="content d-flex flex-column align-items-center justify-content-center">
                <div>
                  <h3 class="text-white fs-5">Akshay Soni</h3>
                  <p class="fs-6 text-white">COO & Co-Founder</p>
                  <p className="text-company">
                    He is the right hand of the company who manages all business
                    development responsibility, extend his harness with
                    customers, and lead guide. Having charming excellence he is
                    involved in various project management discussions and
                    deliver the best to clients.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="box">
            <div class="body">
              <div class="imgContainer">
                <Image src={piyush} alt="" className="bg-company" />
              </div>
              <div class="content d-flex flex-column align-items-center justify-content-center">
                <div>
                  <h3 class="text-white fs-5">Piyush Soni</h3>
                  <p class="fs-6 text-white">CFO & Co-Founder</p>
                  <p className="text-company">
                    He inhales all the digital presence in his and covers the
                    responsibility dazzlingly along with the assortment of SEO,
                    SMO, SEM, SMM, ASO etc. With his brilliant ideas and
                    strategies, we accomplish what we want.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* DPIIT Recognition Section */}
      <section className="recognition-section">
        <h2>Recognitions & Certifications</h2>
        <div className="dpiit-recognition">
          <div className="dpiit-text">
            <p>
              Proudly recognized by the Department for Promotion of Industry and
              Internal Trade (DPIIT) under the Startup India Initiative.
            </p>
            <div className="row text-center justify-content-center">
              <img
                src={startupIndiaLogo}
                alt="DPIIT Certificate"
                className="dpiit-logo col-3"
              />
              <img
                src={dpiitLogo}
                alt="DPIIT Certificate"
                className="dpiit-logo col-3"
              />
            </div>

            <img
              src={dpiitCertificate}
              alt="DPIIT Certificate"
              className="dpiit-certificate col-6"
            />
          </div>
        </div>
      </section>

      <Contact />
    </div>
  );
};

export default About;
