// src/components/Header.js
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import '../assets/styles/Header.css'; // Create a CSS file for styling
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Button, Image } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import logo from "../assets/images/logo.png";
const Header = () => {
  const location = useLocation();
  const [scrollPos, setScrollPos] = useState(true);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", () => {
        console.log(window.scrollY);
        if (window.scrollY === 0) {
          setScrollPos(true);
        } else {
          setScrollPos(false);
        }
      });
    }
  }, []);

  console.log(location.pathname);
  return (
    <Navbar expand="lg" bg={scrollPos == 0 ? "light" : ""} fixed="top">
      <Container>
        <Navbar.Brand href="/" className="company-name">
          <img className="logoimg" src={logo} />
          <span className="maru">MARU</span>
          <span className="mitra">MiTRA</span>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse className="justify-content-end">
          <Nav className="me-auto">
            <Nav.Link
              href="/"
              className={location.pathname == "/" ? "active " : ""}
            >
              Home
            </Nav.Link>
            <Nav.Link
              href="/services"
              className={location.pathname == "/services" ? "active" : ""}
            >
              Servcies
            </Nav.Link>
            <Nav.Link
              href="/about"
              className={location.pathname == "/about" ? "active" : ""}
            >
              About
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
        <Navbar.Collapse className="justify-content-end">
          <Nav.Link href="/contact-us" className="justify-content-end me-4">
            <Button variant="dark">Contact US</Button>
          </Nav.Link>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
