// src/pages/Contact.js
import React, { useEffect, useRef, useState } from "react";

import Banner from "../components/Banner";

import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import { FloatingLabel, Modal } from "react-bootstrap";
import { ReactComponent as MailSvg } from "../assets/images/mail.svg";
import emailjs from "emailjs-com";
const ContactUs = () => {
  const [validated, setValidated] = useState(false);
  const [show, setShow1] = useState(false);
  const formRef = useRef();

  // useEffect(() => {
  //   console.log(stateForm);
  //   if (stateForm.succeeded) {
  //     setShow1(true);
  //     formRef.current.reset();
  //     setValidated(false);
  //   }
  // }, [stateForm.succeeded]);

  const handleSubmitForm = (event) => {
    console.log(
      "formdata",
      process.env.REACT_APP_SERVICE_ID,
      process.env.REACT_APP_TEMPLATE_ID,
      process.env.REACT_APP_PUBLIC_KEY
    );
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      emailjs
        .sendForm(
          process.env.REACT_APP_SERVICE_ID,
          process.env.REACT_APP_TEMPLATE_ID,
          event.target,
          process.env.REACT_APP_PUBLIC_KEY
        )
        .then(
          (result) => {
            setShow1(true);
            formRef.current.reset();
            setValidated(false); // hide message after 5 seconds
          },
          (error) => {}
        );

      // Clears the form after sending the email
    }
    setValidated(true);
  };
  const handleClose = () => {
    console.log("here close", show);
    setShow1(false);
    console.log("here close", show);
  };
  return (
    <div>
      <Banner>
        <div className="welcome-content">
          <p className="animated animatedFadeInUp fadeInUp first">
            Want to talk
          </p>
          {/* <h3 className="animated animatedFadeInUp fadeInUp second">
            {" "}
            ABOUT YOUR PROJECT?{" "}
          </h3> */}
          <h1 className="animated animatedFadeInUp fadeInUp text-uppercase second">
            {" "}
            ABOUT YOUR PROJECT?
          </h1>
          <div className="mb-4 mt-4">
            <h3 className="animated animatedFadeInUp fadeInUp third">
              {" "}
              Would love to hear from you. Do write to us about any queries you
              may have.
            </h3>
            <div className="animated animatedFadeInUp fadeInUp fourth">
              You’ll find we always want to talk about your projects. Talk to us{" "}
              in person.
            </div>
          </div>
          <p></p>
        </div>
      </Banner>
      <div className="container my-5">
        <div className="mt-5 row">
          <div className="col-lg-4 col-md-12">
            <h5 className="badge bg-secondary">Hire MARUMiTRA</h5>
            <h2 className="">Let's work together</h2>
            <div className="row">
              <div className="col-3">
                <a
                  href="#"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() =>
                    (window.location = "mailto:info@marumitra.com")
                  }
                >
                  <MailSvg />
                </a>
              </div>
              <div className="col-2">
                <a
                  href="https://www.linkedin.com/company/marumitra"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="bi bi-linkedin fs-2"></i>
                </a>
              </div>
              <div className="col-2">
                <a
                  href="https://www.facebook.com/profile.php?id=61562262957659&mibextid=ZbWKwL"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="bi bi-facebook text-info fs-2"></i>
                </a>
              </div>
              <div className="col-2">
                <a
                  href="https://twitter.com/marumitra_offic"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="bi bi-twitter-x text-dark fs-2"></i>
                </a>
              </div>
              <div className="col-2">
                <a
                  href="https://www.instagram.com/marumitra?igsh=MWtmOWJoMHJnZDRkOA=="
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="bi bi-instagram instagram fs-2"></i>
                </a>
              </div>
            </div>
            {/* Add more contact details as needed */}
          </div>
          <div className="col-lg-8 col-md-12 shadow-lg bg-body rounded p-3">
            <h2>Get in Touch</h2>
            <Form
              noValidate
              ref={formRef}
              validated={validated}
              onSubmit={handleSubmitForm}
            >
              <Row className="mb-3">
                <Form.Group
                  as={Col}
                  md="12"
                  lg="6"
                  controlId="validationFullName"
                >
                  <FloatingLabel controlId="fullName" label="Full Name">
                    <Form.Control
                      name="fullName"
                      required
                      type="text"
                      placeholder="Full Name"
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter full name.
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Form.Group>

                <Form.Group
                  as={Col}
                  md="12"
                  lg="6"
                  controlId="validationCompanyName"
                >
                  <FloatingLabel
                    controlId="companyName"
                    label="Company/Business Name"
                  >
                    <Form.Control
                      required
                      type="text"
                      placeholder="Company/Business Name"
                      name="companyName"
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide Company or Business Name.
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} md="12" lg="6" controlId="validationEmail">
                  <FloatingLabel controlId="email" label="Email">
                    <Form.Control
                      required
                      type="email"
                      placeholder="Email"
                      aria-describedby="inputGroupPrepend"
                      name="email"
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter valid email address.
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Form.Group>

                <Form.Group
                  as={Col}
                  md="12"
                  lg="6"
                  controlId="validationMobileNumber"
                >
                  <FloatingLabel controlId="mobileNumber" label="Mobile Number">
                    <Form.Control
                      type="number"
                      max={9999999999}
                      min={1000000000}
                      required
                      placeholder="Mobile Number"
                      name="mobileNumber"
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter a valid mobile number.
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group
                  as={Col}
                  md="12"
                  lg="12"
                  controlId="validationSubject"
                >
                  <FloatingLabel controlId="subject" label="Subject">
                    <Form.Control
                      type="text"
                      placeholder="Subject"
                      name="subject"
                    />
                  </FloatingLabel>
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group
                  as={Col}
                  md="12"
                  lg="12"
                  controlId="validationQuery"
                >
                  <FloatingLabel controlId="query" label="Your Query">
                    <Form.Control
                      as="textarea"
                      rows={4}
                      placeholder="Your Query"
                      required
                      style={{ height: "100px" }}
                      name="query"
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide a valid zip.
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Form.Group>
              </Row>
              <Form.Group className="mb-3">
                <Form.Check
                  type="switch"
                  label="Agree to WhatsApp communication"
                  name="whatsappPermission"
                />
              </Form.Group>
              <Button type="submit">Submit form</Button>
            </Form>
          </div>
        </div>
      </div>
      <Modal
        show={show}
        onHide={() => {
          handleClose();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-success">
            Thank You for Contacting Us!
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          We appreciate your interest. Our team will get back to you as soon as
          possible.
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              handleClose();
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
export default ContactUs;
