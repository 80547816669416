// src/pages/Services.js
import React from "react";
import Banner from "../components/Banner";
import { Button, Image } from "react-bootstrap";
import implementImg from "../assets/images/implement.png";
import assessmentImg from "../assets/images/assessments.png";
import supportImg from "../assets/images/support.png";
import optimiseImg from "../assets/images/optimise.png";

const Services = () => {
  return (
    <div>
      <Banner>
        <div class="welcome-content">
          <h5 class="animated animatedFadeInUp fadeInUp first">
            {" "}
            Discover Our Expertise{" "}
          </h5>
          <h3 class="animated animatedFadeInUp fadeInUp text-uppercase second ">
            {" "}
            Transforming Ideas into Reality
          </h3>
          <h5 class="animated animatedFadeInUp fadeInUp third ">
            Through our quality work
          </h5>
          <p class="animated animatedFadeInUp fadeInUp fourth">
            At MARUMiTRA, we specialize in leveraging advanced technologies to
            deliver top-notch solutions that drive business growth and
            innovation.
          </p>
          <p class=" animated animatedFadeInUp fadeInUp fifth">
            Our team of experts is dedicated to providing exceptional service
            and cutting-edge solutions in AI, UI/UX design, cloud deployment,
            and more.
          </p>
        </div>
      </Banner>
      <section className="container">
        <div className="row mt-5">
          <div className="col-12 shadow bg-body-tertiary px-0 mb-5 rounded">
            <h3 className="text-center bg-company text-light text-uppercase rounded">
              Implement
            </h3>
            <div className="row container">
              <div className="col-lg-8 col-md-12 col-sm-12">
                <div className="row">
                  <div className="col-lg-6 col-md-12 row p-5">
                    <i class="bi bi-columns-gap col-2 fs-2 text-company"></i>
                    <div className="col-10">
                      <div className="fw-semibold">IT SERVICE MANAGEMENT</div>
                      <div className="fs-6 fw-lighter">
                        Manage incidents, requests, changes, &amp; problems,
                        automate &amp; streamline IT processes, etc.
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12 row p-5">
                    <i class="bi bi-life-preserver col-2 fs-2 text-company"></i>
                    <div className="col-10">
                      <div className="fw-semibold">IT OPERATION MANAGEMENT</div>
                      <div className="fs-6 fw-lighter">
                        Analytics &amp; reporting capabilities to identify &amp;
                        resolve issues before they become major.
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12 row p-5">
                    <i class="bi bi-briefcase col-2 fs-2 text-company"></i>
                    <div className="col-10">
                      <div className="fw-semibold">SPM</div>
                      <div className="fs-6 fw-lighter">
                        Plan, deliver, and track value &amp; drive more customer
                        value faster.
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12 row p-5">
                    <i class="bi bi-clipboard-data col-2 fs-2 text-company"></i>
                    <div className="col-10">
                      <div className="fw-semibold">GRC</div>
                      <div className="fs-6 fw-lighter">
                        Get real-time visibility and control over your
                        organization's compliance posture
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 col-sm-12 text-center">
                <Image src={implementImg} className="w-80 h-80 " />
              </div>
            </div>
          </div>

          <div className="col-12 shadow bg-body-tertiary px-0 mb-5 rounded">
            <h3 className="text-center bg-company text-light text-uppercase rounded">
              Consult
            </h3>
            <div className="row container">
              <div className="col-lg-8 col-md-12 col-sm-12  order-lg-1">
                <div className="row">
                  <div className="col-lg-6 col-md-12 row p-5">
                    <i class="bi bi-building-check col-2 fs-2 text-company"></i>
                    <div className="col-10">
                      <div className="fw-semibold">Assessments</div>
                      <div className="fs-6 fw-lighter">
                        Let’s assess your current state to develop a long-term
                        strategy that scales with your evolving business.
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12 row p-5">
                    <i class="bi bi-robot col-2 fs-2 text-company"></i>
                    <div className="col-10">
                      <div className="fw-semibold">Modernization </div>
                      <div className="fs-6 fw-lighter">
                        Let’s modernize your enterprise’s legacy infrastructure
                        with ServiceNow’s One Platform.
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12 row p-5">
                    <i class="fas fa-route col-2 fs-2 text-company"></i>
                    <div className="col-10">
                      <div className="fw-semibold">Strategy & Roadmapping </div>
                      <div className="fs-6 fw-lighter">
                        Aligning ServiceNow modules to your business strategy to
                        determine your road to success.
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12 row p-5">
                    <i class="far fa-compass col-2 fs-2 text-company"></i>
                    <div className="col-10">
                      <div className="fw-semibold">
                        Architectural Guidance & Design{" "}
                      </div>
                      <div className="fs-6 fw-lighter">
                        Helping you implement the best mix of ServiceNow modules
                        with reusable components.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 col-sm-12  order-lg-12 text-center">
                <Image src={assessmentImg} className="w-80 h-80 " />
              </div>
            </div>
          </div>

          <div className="col-12 shadow bg-body-tertiary px-0 mb-5 rounded">
            <h3 className="text-center bg-company text-light text-uppercase rounded">
              Support
            </h3>
            <div className="row container">
              <div className="col-lg-8 col-md-12 col-sm-12">
                <div className="row">
                  <div className="col-lg-6 col-md-12 row p-5">
                    <i class="bi bi-headset col-2 fs-2 text-company"></i>
                    <div className="col-10">
                      <div className="fw-semibold">Support & Enhancement </div>
                      <div className="fs-6 fw-lighter">
                        Helping with the enhancement of your existing platform
                        functionality, integrations, as well as migration.
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12 row p-5">
                    <i class="bi bi-wrench-adjustable-circle col-2 fs-2 text-company"></i>
                    <div className="col-10">
                      <div className="fw-semibold">
                        Application Maintenance{" "}
                      </div>
                      <div className="fs-6 fw-lighter">
                        Solving issues in the system with root cause
                        identification and service-level management.
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12 row p-5">
                    <i class="bi bi-cloud-arrow-up col-2 fs-2 text-company"></i>
                    <div className="col-10">
                      <div className="fw-semibold">Upgrade </div>
                      <div className="fs-6 fw-lighter">
                        Helping you to gear up for new ServiceNow releases with
                        zero downtime and disruption.
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12 row p-5">
                    <i class="bi bi-patch-check col-2 fs-2 text-company"></i>
                    <div className="col-10">
                      <div className="fw-semibold">
                        Training & User Adoption{" "}
                      </div>
                      <div className="fs-6 fw-lighter">
                        Driving ServiceNow implementation success by providing
                        adequate training and support to your users.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 col-sm-12  text-center">
                <Image src={supportImg} className="w-80 h-80 " />
              </div>
            </div>
          </div>

          <div className="col-12 shadow bg-body-tertiary px-0 mb-5 rounded">
            <h3 className="text-center bg-company text-light text-uppercase rounded">
              Optmise
            </h3>
            <div className="row container">
              <div className="col-lg-8 col-md-12 col-sm-12  order-lg-1">
                <div className="row">
                  <div className="col-lg-6 col-md-12 row p-5">
                    <i class="bi bi-repeat col-2 fs-2 text-company"></i>
                    <div className="col-10">
                      <div className="fw-semibold">Re-Implementation </div>
                      <div className="fs-6 fw-lighter">
                        Restrategizing your ServiceNow architecture so that it
                        supports your business goals and objectives.
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12 row p-5">
                    <i class="bi bi-motherboard col-2 fs-2 text-company"></i>
                    <div className="col-10">
                      <div className="fw-semibold">License Review </div>
                      <div className="fs-6 fw-lighter">
                        Utilization of newly introduced ServiceNow modules and
                        features maintaining the same licensing cost.
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12 row p-5">
                    <i class="bi bi-graph-up-arrow col-2 fs-2 text-company"></i>
                    <div className="col-10">
                      <div className="fw-semibold">
                        Performance Improvement{" "}
                      </div>
                      <div className="fs-6 fw-lighter">
                        Identifying and improving slow queries, establishing
                        data archival policy, monitoring integrations, etc.
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12 row p-5">
                    <i class="bi bi-tools col-2 fs-2 text-company"></i>
                    <div className="col-10">
                      <div className="fw-semibold">Quick Fix </div>
                      <div className="fs-6 fw-lighter">
                        Keeping your system alive by fixing issues related to
                        integrations, incidents, and other infrastructure
                        elements.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 col-sm-12  order-lg-12 text-center">
                <Image src={optimiseImg} className="w-80 h-80 " />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Services;
